import { memo, useCallback, useRef } from 'react'

import dynamic from 'next/dynamic'

import { ModalRefsProps } from 'components/modal'
import { Layout } from 'modules/layout'
import { pushDataLayer } from 'utils/push-data-layer'

import Form from './form'
import Hero from './hero'

const ForYou = dynamic(() => import('./for-you'))
const ConsortiumTypes = dynamic(() => import('./consortium-types'))
const Benefits = dynamic(() => import('./benefits'))
const WhyChoose = dynamic(() => import('./why-choose'))
const ConsortiumSteps = dynamic(() => import('./consortium-steps'))
const AwardedFaster = dynamic(() => import('./awarded-faster'))
const ConsortiumFinancing = dynamic(() => import('./consortium-financing'))
const BlogLinks = dynamic(() => import('./blog-links'))
const Help = dynamic(() => import('./help'))

const Consortium = memo(() => {
  const ref = useRef<ModalRefsProps>(null)

  const handleOpenModal = useCallback((location: string, label: string) => {
    pushDataLayer({
      category: 'Clique',
      action: 'Botões',
      label: `${location} - Consórcio - ${label}`
    })

    ref?.current?.openModal()
  }, [])

  return (
    <Layout>
      <Hero onClick={() => handleOpenModal('Hero', 'Saiba mais')} />
      <ForYou />
      <ConsortiumTypes />
      <Benefits />
      <WhyChoose />
      <ConsortiumSteps onClick={() => handleOpenModal('ConsortiumSteps', 'Saiba mais')} />
      <AwardedFaster />
      <ConsortiumFinancing onClick={() => handleOpenModal('ConsortiumFinancing', 'Saiba mais')} />
      <BlogLinks onClick={() => handleOpenModal('BlogLinks', 'Saiba mais')} />
      <Help />
      <Form ref={ref} />
    </Layout>
  )
})

Consortium.displayName = 'Consortium'

export default Consortium
