import { FC, memo } from 'react'

import { Text, Button } from '@cash-me/react-components'
import NextImage from 'next/image'

import { Breadcrumb } from 'components/breadcrumb'
import { Grid, Col } from 'components/grid'
import useWindowSize from 'hooks/useWindowSize'

import { HeroProps } from './hero.model'
import * as S from './styles'

const Hero: FC<HeroProps> = memo(({ onClick }) => {
  const { isMobile } = useWindowSize()
  const BreadcrumbLinks = [{ label: 'Consórcio' }]

  return (
    <S.Hero>
      <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
        <Col col={{ tablet: 5, desktop: 5 }} start={{ tablet: 2, desktop: 2 }}>
          <S.Infos>
            <Breadcrumb items={BreadcrumbLinks} />
            <Text as="h1" size="heading2" weight="medium" color="var(--colors-neutral-text-default)">
              Consórcio CashMe:{' '}
              <Text as="span" size="heading2" weight="medium" color="var(--colors-pink-text-default)">
                Conquiste seu Imóvel ou Carro!
              </Text>
            </Text>
            <Text color="var(--colors-neutral-text-weak)">A realização do seu sonho fica mais fácil com a CashMe.</Text>
            <Button size="lg" onClick={onClick} iconName="arrowRight">
              Saiba mais
            </Button>
          </S.Infos>
        </Col>

        {isMobile ? (
          ' '
        ) : (
          <Col col={{ tablet: 6, desktop: 6 }} start={{ tablet: 7, desktop: 7 }}>
            <NextImage
              src="/images/consortium/hero-image.png?"
              alt="Casa e carro"
              quality="100"
              priority
              fill
              style={{
                objectFit: 'contain'
              }}
            />
          </Col>
        )}
      </Grid>
    </S.Hero>
  )
})

Hero.displayName = 'Hero'

export default Hero
