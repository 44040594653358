import styled from 'styled-components'

import { theme } from 'styles/theme'

const { space, colors, radii } = theme

export const Wrapper = styled.div`
  #consorcio-form {
    padding-right: ${space['spacing-md']};
  }

  & > div > div > button + div {
    margin-bottom: ${space['spacing-xxs']};
  }

  h2 {
    color: ${colors['neutral-text-default']};
  }

  label {
    display: none!important;
  }

  input, select {
    padding: ${space['spacing-sm']} ${space['spacing-xs']}!important;
    border-radius: ${radii['corner-radius-sm']}!important;
    color: ${colors['neutral-text-default']};
    border-color: ${colors['neutral-border-default']};
    background-color: ${colors['neutral-background-default']}!important;
  }

  a {
    color: ${colors['blue-text-default']};
    text-decoration: underline!important;
  }

  .submitButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: ${colors['blue-background-strong-hover']}!important;
    padding: ${space['spacing-sm']} ${space['spacing-xs']};
  }

  .submitButton::after {
    content: 'V';
    transform: rotate(270deg);
  }
`
