import styled from 'styled-components'

import { theme } from 'styles/theme'

const { media, space, fontSizes } = theme

export const Hero = styled.section`
  position: relative;
  width: 100%;
  padding-top: ${space['spacing-md']};
  padding-bottom: ${space['spacing-xxxl']};
  background-color: #f0f7ff;

  & > div > div:nth-child(2) {
    position: relative;
  }
`

export const Infos = styled.div`
  h1, p {
    margin-bottom: ${space['spacing-md']};
  }
    
  .ds-button {
    width: 100%;
  }

  @media (${media.tablet}) {
    margin: ${space['spacing-md']} 0 ${space['spacing-md']} 0;

    .ds-button {
      width: 17rem;
    }

    .ds-button span {
      display: flex;
      align-items: center;
    }
  }

  @media (${media.desktop}) {
    h1,
    span {
      font-size: ${fontSizes['font-size-heading-1']};
    }

    h1 {
      margin-bottom: ${space['spacing-xl']};
    }

    p {
      margin-bottom: ${space['spacing-xl']};
    }
  }
`
