import { useRef, forwardRef, useImperativeHandle } from 'react'

import Script from 'next/script'

import { DynamicFormNew } from 'components/general-styles'
import { Modal, ModalRefsProps } from 'components/modal'

import * as S from './styles'

const Form = forwardRef((props, ref) => {
  const modalRef = useRef<ModalRefsProps>(null)
  const dataFormId = '97ff0d57-3fec-ee11-a203-6045bd3c9124'
  const idUrl = '55b45824-bf01-4a9f-8a36-e21331ee75ce'

  useImperativeHandle(ref, () => ({
    openModal() {
      modalRef.current?.openModal()
    }
  }))

  return (
    <S.Wrapper>
      <Modal ref={modalRef} title="Contate um consultor">
        <DynamicFormNew id="consorcio-form">
          <div
            data-form-id={dataFormId}
            data-form-api-url={`https://public-sam.mkt.dynamics.com/api/v1.0/orgs/${idUrl}/landingpageforms`}
            data-cached-form-url={`https://assets-sam.mkt.dynamics.com/${idUrl}/digitalassets/forms/${dataFormId}`} />
          <Script
            id="FormLoader"
            src="https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/sam/FormLoader/FormLoader.bundle.js"
            strategy="lazyOnload"
          />
        </DynamicFormNew>
      </Modal>
    </S.Wrapper>
  )
})

Form.displayName = 'Form'

export default Form
