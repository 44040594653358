import type { NextPage } from 'next'

import { consortium } from 'data/seo'
import Consortium from 'src/presentation/views/consortium'

const ConsortiumPage: NextPage = () => <Consortium />

export default ConsortiumPage

export async function getStaticProps() {
  return {
    props: { SEO: consortium }
  }
}
